import React, {
  FunctionComponent,
  useState,
  useEffect,
  ChangeEvent,
} from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Blog as BlogType } from "../types"
import OnScreen from "../components/OnScreen"
import Button from "../components/Button"

interface Props {
  data: any
}

const Blog: FunctionComponent<Props> = ({ data }) => {
  const [showForm, setShowForm] = useState(false)
  const [text, setText] = useState("Sign Up For Blog")
  const [searchTerm, setSearchTerm] = useState("")
  const [category, setCategory] = useState("")
  // const [searchResult, setSearchResult] = useState('')

  const blogs = data.allContentfulBlog.edges.map((elem: any) => elem.node)

  const cats = data.allContentfulCategories.edges.map((elem: any) => elem.node)

  const newBlogs =
    category == ""
      ? blogs
      : blogs.filter(blog =>
          blog?.categories?.some(cat => cat.category === category)
        )

  const [searchResult, setSearchResult] = useState(newBlogs)

  useEffect(() => {
    setSearchResult(newBlogs)
  }, [category])
  const handleSearch = (value: any) => {
    setSearchTerm(value)
    if (value !== "") {
      let result = newBlogs.filter(
        blog =>
          blog.title.toLowerCase().includes(value.toLowerCase()) ||
          blog.content.raw.toLowerCase().includes(value.toLowerCase())
      )
      setSearchResult(result)
    } else {
      setSearchResult(newBlogs)
    }
  }

  const handleFaqFilter = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchTerm(e.target.value)
  }

  function formatDate(date: Date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }

  return (
    <Layout>
      <SEO
        title="Gamify Your Habits Blog"
        description="Discover how to gamify your life by aligning core values with daily actions. Explore strategies to boost motivation, productivity, and personal growth."
        link="https://mooremomentum.com/blog/"
        image="https://mooremomentum.com/static/1b5d3327fe514e605e4715571b39b710/6ea1f/gamify-def.webp"
      />

      <div className="bg-blue mt-4">
        <div className="container mx-auto px-4">
          <h1 className="font-Orbitron py-4 text-2xl text-white sm:text-3xl lg:py-8 lg:text-4xl">
            Gamify Your Habits Blog
          </h1>
        </div>
      </div>

      <div className="max-w-4xl mx-auto px-4 py-8">
        {!showForm ? (
          <div className="flex justify-center items-center">
            <button
              onClick={() => setShowForm(!showForm)}
              className="bg-coral animate-pulse font-Orbitron text-white rounded-full px-6 py-4 text-xl md:text-2xl lg:text-3xl"
            >
              {text}
            </button>
          </div>
        ) : (
          <>
            <div className="my-8 text-center">
              <h2 className="font-Orbitron mb-4 text-2xl md:text-3xl">
                Sign Up For Blog
              </h2>

              <p>
                For weekly tips to gamify your habits to level up your life.
              </p>
            </div>

            <form
              action="https://mooremomentum.us20.list-manage.com/subscribe/post"
              method="POST"
            >
              <input type="hidden" name="u" value="705d046da14d7bc6e462e6ec4" />
              <input type="hidden" name="id" value="fc1be8fe04" />
              {/* This hidden input is the blog tag */}
              <input type="hidden" name="tags" value="2083449" />
              {/* Magic hidden input for blog tag */}
              <div className="mb-8 space-y-8">
                <div className="gap-8 grid sm:grid-cols-2">
                  <div>
                    <input
                      className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                      id="MERGE1"
                      name="MERGE1"
                      placeholder="First Name"
                      required
                      type="text"
                    />
                  </div>

                  <div>
                    <input
                      className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                      id="MERGE2"
                      name="MERGE2"
                      placeholder="Last Name"
                      required
                      type="text"
                    />
                  </div>
                </div>

                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                    id="MERGE0"
                    name="MERGE0"
                    placeholder="Email Address"
                    required
                    type="email"
                  />
                </div>
              </div>

              <div className="text-center">
                <Button className="px-12" type="submit">
                  Subscribe
                </Button>
              </div>
            </form>
          </>
        )}
      </div>

      <div className="max-w-6xl mx-auto my-8 px-4">
        <div className="flex items-center lg:flex-row flex-col">
          <form className="mb-10 lg:w-3/5 w-full">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                type="search"
                id="blog-search"
                placeholder="Search Blogs"
                required
                value={searchTerm}
                onChange={e => handleSearch(e.target.value)}
              />
            </div>
          </form>
          <div className="mb-10 lg:pl-6 pl-0 lg:w-2/5 w-full">
            <select
              className="w-full"
              onChange={e => setCategory(e.currentTarget.value)}
            >
              <option value={""}>Select Category</option>
              {cats?.map(category => (
                <option key={category?.category} value={category?.category}>
                  {" "}
                  {category?.category}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="gap-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {searchResult &&
            searchResult.map(elem => (
              <OnScreen key={elem.id} classToAdd="scale-in-center">
                <div className="blog-widget bg-gray p-4 rounded-md shadow-lg">
                  <Link to={`/blog/${elem.slug}`}>
                    <GatsbyImage
                      alt={
                        elem.coverImage?.description
                          ? elem.coverImage?.description
                          : elem.title
                      }
                      image={elem.coverImage?.gatsbyImageData}
                    />

                    <div className="mt-4">
                      <h2 className="font-Orbitron mb-4 md:text-lg lg:text-xl">
                        {elem.title.length > 69
                          ? elem.title.substring(0, 69) + "..."
                          : elem.title}
                      </h2>

                      <p>{formatDate(new Date(elem.datePublished))}</p>
                    </div>
                  </Link>
                </div>
              </OnScreen>
            ))}
        </div>
        {searchResult?.length === 0 && (
          <h2 className="text-center text-3xl">No Blogs Found</h2>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulBlog(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: [datePublished], order: DESC }
    ) {
      edges {
        node {
          coverImage {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          datePublished
          id
          slug
          title
          content {
            raw
          }
          categories {
            category
          }
        }
      }
    }
    allContentfulCategories(sort: { updatedAt: DESC }) {
      edges {
        node {
          category
        }
      }
    }
  }
`

export default Blog
